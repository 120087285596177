import {
  IAccessPolicyStatementCrunch,
  TCRUDA,
} from 'services/api/staff/access-policy/IAccessPolicy';
import { IStartMenu } from 'services/interfaces/IMenuItemV2';
import {
  actionCodeForDisplayFolderDefault,
  additionalFilterFileBucketArchive,
} from '../useLoadMenu';

export const extractFilterSubMenu =
  (
    currentEmployeeAccessPolicy: IAccessPolicyStatementCrunch,
    statementName: string,
    actionCodeForDisplayFolder: TCRUDA = actionCodeForDisplayFolderDefault
  ) =>
  (menu: IStartMenu): boolean => {
    if (
      currentEmployeeAccessPolicy?.[statementName]?.[menu.name] === undefined
    ) {
      return false;
    }
    return (
      currentEmployeeAccessPolicy?.[statementName]?.[menu.name]?.includes(
        actionCodeForDisplayFolder
      ) ||
      additionalFilterFileBucketArchive(currentEmployeeAccessPolicy, menu) ||
      false
    );
  };
