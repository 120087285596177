import {
  IAccessPolicyStatement,
  TCRUDA,
  TServicesKeys,
} from 'services/api/staff/access-policy/IAccessPolicy';
import { IStartMenu } from 'services/interfaces/IMenuItemV2';
import { TAdditional, TValueOfIAccessPolicyStatement } from '../useLoadMenu';
import { hasCommonActionCode } from './hasCommonCode';

export const extractAdditionalFilter =
  <T extends TServicesKeys>(additional: TAdditional<T>) =>
  (accessPolicy: IAccessPolicyStatement, menu: IStartMenu) => {
    // Получаем имя сервиса
    const keyService = Object.keys(additional)[0] as T;

    // Определяем есть ли в доступе у сотрудника такой сервис
    const accessPolicyForKeyService = accessPolicy?.[
      keyService
    ] as TValueOfIAccessPolicyStatement<T>;

    // Проверяем, что keyService есть в accessPolicy
    if (accessPolicyForKeyService) {
      // Получаем имя модели
      const keyModel = Object.keys(
        additional[keyService]
      )[0] as keyof TValueOfIAccessPolicyStatement<T>;

      // Получаем   коды дял фильтрации
      const codesFilter = additional[keyService]?.[keyModel] as TCRUDA[];

      if (menu.name === keyModel) {
        // Получаем  доступные коды доступа у сотрудника
        const codesEmployee = (accessPolicyForKeyService as any)?.[
          keyModel
        ] as TCRUDA[];

        // Если есть совпадения в кодах доступа модель и в кодах которые
        // разрешат доступ для раздела получаем true
        return hasCommonActionCode(codesEmployee, codesFilter);
      }
    }
    return false;
  };
