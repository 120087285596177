import { TCRUDA } from 'services/api/staff/access-policy/IAccessPolicy';

export const hasCommonActionCode = (
  actionCodes1: TCRUDA[],
  actionCodes2: TCRUDA[]
): boolean => {
  const set1 = new Set(actionCodes1);
  for (const elem of actionCodes2) {
    if (set1.has(elem)) {
      return true;
    }
  }
  return false;
};
